
export const localstorage = {
    clear: clear,
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem
}
function clear() {
    localStorage.clear();
}

function setItem(key: any, value: any) {
    return localStorage.setItem(key, value);
}

function getItem(key: any) {
    return localStorage.getItem(key);
}

function removeItem(key: any) {
    return localStorage.removeItem(key);
}