import React, { useEffect, useState } from 'react';
import './StallCouponForm.scss';
import { Button, Link } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { http, formValidator, AlertService, FxInput, FxSelect, FxDate, formatService } from '../../fx-core';

const StallCouponForm = (props) => {
    const currentDate = formatService.getCurrentDateForServer();
    const defaultCoupon = {
        id: props.id,
        couponCode: "",
        couponValue: "",
        couponValueTypeId: -1,
        validFrom: "",
        validTo: "",
        entityId: props.stallId,
        entityTypeId: 3, //3-Stall
        couponTypeId: 2, //2-Promotion
        allowedCount: 0
    };
    const [coupon, setCoupon] = useState(defaultCoupon);
    const [couponValueType, setCouponValueType] = useState([]);
    const [errors, setErrors] = useState({});

    const handleInputChange = (item) => {
        handleItemChange({ [item.name]: item.value });
        if (item.name == "couponValueTypeId") {
            item.value = item.value == -1 ? "" : item.value + '';
        }
        item.validations = getInputValidations(item.name);
        const validationResult = formValidator.validate(item);
        const validationData = { [item.name]: validationResult };
        updateErrors(validationData);
    };

    const handleItemChange = (data) => {
        setCoupon(prevState => {
            return { ...prevState, ...data }
        });
    };

    const getValidationMap = () => {
        let validationMap = {
            couponCode: ["required"],
            couponValue: ["required"],
            couponValueTypeId: ["required"],
            validFrom: ["required"],
            validTo: ["required"]
        };
        return validationMap;
    };

    const getInputValidations = (name) => {
        let validationMap = getValidationMap();
        return validationMap[name];
    };

    const bulkValidate = () => {
        let items: any = [];
        let validationMap = getValidationMap();
        for (var key in validationMap) {
            if (key == "couponValueTypeId") {
                let val = coupon[key] == -1 ? "" : coupon[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                items.push(rec);
            }
            else if (key == "couponValue") {
                let val = coupon[key] == null ? "" : coupon[key] + '';
                let rec = { name: key, value: val, validations: validationMap[key] }
                items.push(rec);
            }
            else {
                let result = { name: key, value: coupon[key], validations: validationMap[key] }
                items.push(result);
            }
        }
        let validationResult = formValidator.bulkValidate(items);
        updateErrors(validationResult.errors);
        return validationResult.isValid;
    };

    const updateErrors = (validationData) => {
        setErrors(prevState => {
            return { ...prevState, ...validationData }
        });
    };

    const hasError = (field, validationMethod) => {
        return (
            errors &&
            errors[field] &&
            errors[field][validationMethod]
        );
    };

    const saveCoupon = () => {
        const isValid = bulkValidate();
        if (isValid) {
            let apiOptions = {
                url: 'coupon/save',
                data: coupon
            };
            http.post(apiOptions).then(response => {
                saveCouponCallBack(response);
            });
        }
    };

    const saveCouponCallBack = (res) => {
        AlertService.showSuccessMsg();
        navToCouponList();
    };

    const navToCouponList = () => {
        props.handleBackToCouponList();
    };

    const initLookup = () => {
        let inputData = {
            lookups: {
                CouponValueType: { default: true }
            },
            filters: {}
        };
        let apiOptions = {
            url: 'options/lookup',
            data: inputData
        };
        http.post(apiOptions).then(response => {
            const result = response.data;
            initLookupCallback(result);
        })
    };

    const initLookupCallback = (result) => {
        setCouponValueType(result.CouponValueType);
        getCouponById();
    };

    const getCouponById = () => {
        if (coupon.id > 0) {
            let apiOptions = {
                url: `coupon/${coupon.id}`,
                data: {}
            };
            http.get(apiOptions).then(response => {
                getCouponByIdCallBack(response);
            });
        }
    };

    const getCouponByIdCallBack = (res) => {
        const result = res.data;
        setCoupon(result);
    };

    useEffect(() => {
        initLookup();
    }, []);

    return (
        <div className='stallcouponform-ui'>
            <div className='drawer-combo'>
                <div className="title-font custom-head pl-2 ">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="primary" className="custom-link pointer breadcrumbs-style" onClick={navToCouponList}>
                            <b>Coupons</b>
                        </Link>
                        <Typography color="primary" className="breadcrumbs-style">
                            <span>Coupon</span>
                        </Typography>
                    </Breadcrumbs>
                </div>
                <div className="m-4">
                    <div className="m-1">
                        <FxInput name="couponCode" variant="outlined" label="Coupon Code" fullWidth required={true}
                            value={coupon.couponCode} onValueChange={handleInputChange} disabled={coupon.id > 0} />
                        {hasError("couponCode", "required") && (
                            <div className="error">Coupon Code is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxInput type="number" name="couponValue" variant="outlined" label="Coupon Value" fullWidth
                            value={coupon.couponValue} onValueChange={handleInputChange} required={true} />
                        {hasError("couponValue", "required") && (
                            <div className="error">Coupon Value is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxSelect name="couponValueTypeId" variant="outlined" label="Coupon Value Type"
                            options={couponValueType}
                            selectedValue={coupon.couponValueTypeId}
                            valueField="id"
                            displayField="text"
                            size="small"
                            fullWidth
                            onValueChange={handleInputChange}
                            required={true} />
                        {hasError("couponValueTypeId", "required") && (
                            <div className="error">Coupon Value Type is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxDate name="validFrom" variant="outlined" label="Valid From" fullWidth
                            value={coupon.validFrom} onValueChange={handleInputChange} required={true} minDate={currentDate}
                        />
                        {hasError("validFrom", "required") && (
                            <div className="error">Valid From is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxDate name="validTo" variant="outlined" label="Valid To" fullWidth
                            value={coupon.validTo} onValueChange={handleInputChange} required={true} minDate={currentDate}
                        />
                        {hasError("validTo", "required") && (
                            <div className="error">Valid To is required</div>
                        )}
                    </div>
                    <div className="m-1 pt-3">
                        <FxInput type="number" name="allowedCount" variant="outlined" label="Coupon Limit" fullWidth
                            value={coupon.allowedCount} onValueChange={handleInputChange} required={true} />
                        {coupon.allowedCount == 0 &&
                            <div className="error">Value 0 means No Limit</div>
                        }
                    </div>
                </div>
                <div className='px-3 py-2 drawer-footer'>
                    <Button color="primary" variant="contained" fullWidth className='btn-style' onClick={saveCoupon}><b>Save</b></Button>
                </div>
            </div>
        </div >
    )
};

export default StallCouponForm;

