import React, { useState } from 'react';
import './PreviewOrder.scss';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FxCurrency } from '../../fx-core';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';

export const PreviewOrder = (props) => {
  const { orderData } = props;
  const order = orderData.order;
  const cartInfo = useSelector<any, any>(store => store.cart);
  const cart = cartInfo;

  return (
    <div className="previewOrder-ui">
      <div className="row col-12 title-font py-1 mx-auto">
        <div className="col-11 pl-0">
          <span>Preview Order</span>
        </div>
        <div className="col-1 pr-0 float-right text-right pointer" onClick={props.closePreviewDialog}>
          <HighlightOffIcon fontSize="small" color="secondary" />
        </div>
      </div>

      <div className='mx-4 my-5'>
        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table className="table-striped" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Items</TableCell>
                <TableCell width={25}>Qty</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right" width={100}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.items.map((item: any, i) => (
                <TableRow key={i}>
                  <TableCell className="table-font">
                    <span className='font-14'>{item.itemName}</span>
                    <div className='font-11 extras-txt'>
                      {
                        item.groupedCustomDetails && item.groupedCustomDetails.length > 0 && item.groupedCustomDetails.map((custom, k) => (
                          <div>
                            <span className='color-txt font-11'>{custom.key} - </span>
                            {
                              custom.displayModifiers.length > 0 && custom.displayModifiers.map((detail, idx) => (
                                <span className='color-txt font-11'>{detail.description}
                                  {detail.price > 0 &&
                                    <span> - <FxCurrency value={detail.price} /></span>
                                  }
                                  {idx != (custom.displayModifiers.length - 1) &&
                                    <span>,</span>
                                  }
                                </span>
                              ))
                            }
                            {k != (item.groupedCustomDetails.length - 1) &&
                              <span className='color-txt font-11'>,</span>
                            }
                          </div>
                        ))
                      }
                    </div>
                  </TableCell>
                  <TableCell className="table-font">
                    <span>{item.quantity}</span>
                  </TableCell>
                  <TableCell align="right">
                    <span className="table-font">{item.quantity} * <FxCurrency value={item.price} />
                      {item.modifierAmt > 0 &&
                        <span>+ <FxCurrency value={item.modifierAmt} /></span>
                      }
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className="table-font">
                      <FxCurrency value={item.totalPrice} />
                    </span>
                  </TableCell>
                </TableRow>
              ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  <span className="table-font">Order Amount</span>
                </TableCell>
                <TableCell align="right">
                  <span className="table-font">
                    <FxCurrency value={order.orderAmount} />
                  </span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  <span className="table-font">Coupon Amount</span>
                </TableCell>
                <TableCell align="right">
                  <span className="table-font">
                    <FxCurrency value={order.couponAmount} />
                  </span>
                </TableCell>
              </TableRow>
              {
                order.orderTypeId == 1 &&
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <span className="table-font">Delivery Charge</span>
                  </TableCell>
                  <TableCell align="right">
                    <span className="table-font">
                      <FxCurrency value={order.deliveryCharge} />
                    </span>
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell align="right" colSpan={3}>
                  {
                    order.orderTypeId == 1 &&
                    <span className="table-font">Total((Order Amount - Coupon Amount) + Delivery Charge)</span>
                  }
                  {
                    order.orderTypeId == 2 &&
                    <span className="table-font">Total(Order Amount - Coupon Amount)</span>
                  }
                </TableCell>
                <TableCell align="right">
                  <span className="table-font">
                    <FxCurrency value={order.orderTotal} />
                  </span>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        {
          order.balanceCouponAmount > 0 &&
          <div className='bold-text font-12 pt-2'>
            Please note that the coupon is for one-time use only, and the remaining value of
            <span> <FxCurrency value={order.balanceCouponAmount} /> </span>
            will be forfeited.
          </div>
        }
        <div className='pt-2 text-right'>
          <button className="btn btn-sm rounded-2 btn-clr" onClick={props.placeOrder}>Place Order</button>
        </div>
      </div>

    </div>
  );
};