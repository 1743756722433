import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/CloudUpload';

export const FxUploader = (props: any) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ maxFiles: 1, accept: props.acceptType })

  const filesDisplay: any = acceptedFiles.map(file => (
    <div>{file.name}</div>
  ));

  const onUpload = () => {
    props.onUpload(acceptedFiles);
  }

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {
          // isDragActive ? <p>Drop the file here ...</p> :
          // <p>Drag 'n' drop file here, or click to select file</p>
          <div>
            <div className="row m-0 mb-2">
              <Button variant="contained" size="small" color="secondary">
                Choose File
              </Button>
            {acceptedFiles && acceptedFiles.length == 0 &&
              <span className='ml-1'>No file chosen</span>
            }
            </div>
            {filesDisplay &&
              <span className='font-12'> {filesDisplay}</span>
            }

          </div>
        }
      </div>
      {/* <div>
        {filesDisplay}
      </div> */}

      <div className="ml-2">
        <Button variant="contained" size="small" color="primary" onClick={onUpload}>
          Upload <span className="ml-1"><UploadIcon /></span>
        </Button>
      </div>

    </>
  )
}