import React, { useEffect, useState } from 'react';
import './OrderSummary.scss';
import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TableFooter } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FxCurrency, http, general, AlertService } from '../fx-core';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import Link from '@material-ui/core/Link';
import useRazorpay from '../utils/useRazorPay';

const OrderSummary = (props: any) => {
  const dispatch = useDispatch();
  const Razorpay = useRazorpay();

  let id = 0;
  if (props.match && props.match.params.orderId) {
    id = parseInt(props.match.params.orderId)
  }
  else if (props.orderId) {
    id = props.orderId
  }
  const [orderId, setOrderId] = useState(id);

  const initialList = [];
  let defaultOrder = {
    orderIdentifier: 0,
    placedDate: null,
    paymentMode: null,
    paymentStatus: null,
    orderTypeId: 0,
    preferredDeliverySlotId: null,
    preferredDeliveryDate: null,
    orderAmountWithoutTax: 0,
    taxAmount: 0,
    deliveryCharge: 0,
    orderTotal: 0,
    stallAddress: null,
    couponAmount: 0,
    orderAmount:0
  };

  let defaultAddress = {
    fullAddress: null,
    pinCode: null
  };

  let defaultSlot = {
    startTime: null,
    endTime: null,
    description: null
  };

  let defaultContext = {
    stripeText: "Card",
    canOpenPaymentLink: false
  };

  const [order, setOrder] = useState(defaultOrder);
  const [address, setAddress] = useState(defaultAddress);
  const [deliverySlot, setDeliverySlot] = useState(defaultSlot);
  const [lines, setLines] = useState(initialList);
  const [orderPayments, setOrderPayments] = useState(initialList);
  const [currentContext, setCurrentContext] = useState(defaultContext);

  const getOrderDetail = () => {
    let inputData = {
      id: orderId
    };

    let apiOptions: any = {
      url: `order/detail/${inputData.id}`
    };

    http.get(apiOptions).then(res => {
      getOrderDetailCallBack(res);
    })
  };

  const getOrderDetailCallBack = (res) => {
    const result = res.data;
    for (let itemObj of result) {
      setOrder(itemObj.order);
      for (let item of itemObj.orderLines) {
        item.imageUrl = general.getImageUrl(item.imagePath);
        item.orderLineModifiers = _.map((_.groupBy(item.orderLineModifiers, function (i) {
          return i.groupName
        })), function (items, key) { return { key: key, displayModifiers: items }; });
      }
      setLines(itemObj.orderLines);
      setAddress(itemObj.address);
      setOrderPayments(itemObj.orderPayments);
      setDeliverySlot(itemObj.deliverySlot);
      emptyCart();
      validateAndInitPayment(itemObj.orderPayments)
    }
  };

  const emptyCart = () => {
    dispatch({ type: "CLEAR_CART", payload: {} });
  };

  const validateAndInitPayment = (payments) => {
    let item = payments.find(item => item.paymentMode == 'razorpay'
      && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated'));
    const canShowLink = item ? true : false;
    if (canShowLink && !currentContext.canOpenPaymentLink) {
      setCurrentContext(prevState => {
        return { ...prevState, ...{ canOpenPaymentLink: true } }
      });
      initiatePayment();
    }
  };

  const initiatePayment = () => {
    let inputData = {
      id: orderId
    };
    let apiOptions: any = {
      url: `order/GetPaymentLink`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      if (res && res.data && res.data.result) {
        openPaymentLink(res);
      }
      else {
        getOrderDetail();
      }
    });
  }

  const openPaymentLink = (resp: any) => {
    var item = resp.data.result;
    let checkoutIdentifier = item.checkoutIdentifier;
    let externalOrderId = item.externalOrderId;
    let amount = item.amount;
    let description = item.description;
    let customerName = item.name;
    let email = item.email;
    let mobile = item.mobile;

    const options: any = {
      amount: amount,
      description: description,
      order_id: externalOrderId,
      handler: async function (response: any) {
        let data: any = {
          Id: item.id,
          ExternalPaymentId: response.razorpay_payment_id,
          ExternalOrderId: externalOrderId
        };
        await updateRazorPaySuccess(data);
        AlertService.showSuccessMsg("Payment Success!");
        getOrderDetail();
      },
      prefill: {
        name: customerName,
        email: email,
        contact: mobile
      }
    };

    const rzp1 = new Razorpay(options);
    rzp1.on("payment.failed", function (response: any) {
      console.log("payment failed, response=", response);
    });

    rzp1.open();
  }

  const updateRazorPaySuccess = async (inputData) => {
    let apiOptions: any = {
      url: `order/updatePaymentSuccess`,
      data: inputData
    };
    await http.post(apiOptions);
  }

  useEffect(() => {
    getOrderDetail();
  }, []);

  return (
    <div className="ordersummary-ui">
      <div className="content-container marginTopStyle">

        {/* Desktop View */}
        <div className='deskview'>
          <div className='row mx-4 my-5'>
            <div className='col-8'>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell width={25}>Qty</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell width={100} align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font">
                          <span className='font-14'>{row.itemName}</span>
                          <div className='font-11 extras-txt'>
                            {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                              <div>
                                <span>{custom.key}-</span>
                                {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                  <span>{detail.description}
                                    {idx != (custom.displayModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                    {i != (row.orderLineModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                  </span>
                                ))}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                            {row.lineModifierAmount > 0 &&
                              <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                            }
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        <span className="table-font">Order Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        <span className="table-font">Coupon Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.couponAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    {
                      order.orderTypeId == 1 &&
                      <TableRow>
                        <TableCell align="right" colSpan={3}>
                          <span className="table-font">Delivery Charge</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={order.deliveryCharge} />
                          </span>
                        </TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        {
                          order.orderTypeId == 1 &&
                          <span className="table-font">Total((Order Amount - Coupon Amount) + Delivery Charge)</span>
                        }
                        {
                          order.orderTypeId == 2 &&
                          <span className="table-font">Total(Order Amount - Coupon Amount)</span>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>

            <div className='col-4 summary-detail font-14'>
              <div className='row'>
                <div className='col-4'>Order #</div>
                <div className='col-8'>: {order.orderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-4'>Ordered Date</div>
                <div className='col-8'>: {general.getFormattedDate(order.placedDate)}</div>
              </div>
              {orderPayments.map((item: any) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {item.paymentMode != 'coupon' &&
                        <span>Order total</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>Payment Mode</div>
                    <div className='col-8'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'razorpay' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                          <span className="pr-1 payment-style">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {item.paymentMode != 'coupon' &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-8'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span className='pl-1'>
                            <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {deliverySlot &&
                <div className='row pt-1'>
                  <div className='col-4'>
                    {order.orderTypeId === 1 &&
                      <span>Delivery</span>
                    }
                    {order.orderTypeId === 2 &&
                      <span>Pickup</span>
                    }
                  </div>
                  <div className='col-8'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
                </div>
              }
              {order.orderTypeId === 1 &&
                <div className='row pt-1'>
                  <div className='col-4'>Delivery Address</div>
                  <div className='col-8'>: {address.fullAddress}</div>
                </div>
              }
              {order.orderTypeId === 2 &&
                <div className='row pt-1'>
                  <div className='col-4'>Pickup Address</div>
                  <div className='col-8'>: {order.stallAddress}</div>
                </div>
              }
            </div>
          </div>
        </div>


        {/* Tablet View */}
        <div className='tabview'>
          <div className='row mx-4 my-5'>
            <div className='col-8'>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell width={25}>Qty</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell width={100} align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font">
                          <span className='font-14'>{row.itemName}</span>
                          <div className='font-11 extras-txt'>
                            {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                              <div>
                                <span>{custom.key}-</span>
                                {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                  <span>{detail.description}
                                    {idx != (custom.displayModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                    {i != (row.orderLineModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                  </span>
                                ))}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                            {row.lineModifierAmount > 0 &&
                              <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                            }
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        <span className="table-font">Order Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        <span className="table-font">Coupon Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.couponAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    {
                      order.orderTypeId == 1 &&
                      <TableRow>
                        <TableCell align="right" colSpan={3}>
                          <span className="table-font">Delivery Charge</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={order.deliveryCharge} />
                          </span>
                        </TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        {
                          order.orderTypeId == 1 &&
                          <span className="table-font">Total((Order Amount - Coupon Amount) + Delivery Charge)</span>
                        }
                        {
                          order.orderTypeId == 2 &&
                          <span className="table-font">Total(Order Amount - Coupon Amount)</span>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>

            <div className='col-4 summary-detail font-14'>
              <div className='row'>
                <div className='col-4'>Order #</div>
                <div className='col-8'>: {order.orderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-4'>Ordered Date</div>
                <div className='col-8'>: {general.getFormattedDate(order.placedDate)}</div>
              </div>
              {orderPayments.map((item: any) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {item.paymentMode != 'coupon' &&
                        <span>Order total</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-8'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>Payment Mode</div>
                    <div className='col-8'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'razorpay' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                          <span className="pr-1 payment-style">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-4'>
                      {item.paymentMode != 'coupon' &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-8'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span className='pl-1'>
                            <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {deliverySlot &&
                <div className='row pt-1'>
                  <div className='col-4'>
                    {order.orderTypeId === 1 &&
                      <span>Delivery</span>
                    }
                    {order.orderTypeId === 2 &&
                      <span>Pickup</span>
                    }
                  </div>
                  <div className='col-8'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
                </div>
              }
              {order.orderTypeId === 1 &&
                <div className='row pt-1'>
                  <div className='col-4'>Delivery Address</div>
                  <div className='col-8'>: {address.fullAddress}</div>
                </div>
              }
              {order.orderTypeId === 2 &&
                <div className='row pt-1'>
                  <div className='col-4'>Pickup Address</div>
                  <div className='col-8'>: {order.stallAddress}</div>
                </div>
              }
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className='mobileview'>
          <div className='mx-3 mb-5 mt-2'>
            <div className='summary-detail font-13 mb-3'>
              <div className='row'>
                <div className='col-5'>Order #</div>
                <div className='col-7'>: {order.orderIdentifier}</div>
              </div>
              <div className='row pt-1'>
                <div className='col-5'>Ordered Date</div>
                <div className='col-7'>: {general.getFormattedDate(order.placedDate)}</div>
              </div>
              {orderPayments.map((item: any) => (
                <div>
                  <div className='row pt-1'>
                    <div className='col-5'>
                      {item.paymentMode != 'coupon' &&
                        <span>Order total</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Coupon Value</span>
                      }
                    </div>
                    <div className='col-7'>: <FxCurrency value={item.amount} /></div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-5'>Payment Mode</div>
                    <div className='col-7'>
                      <span>: </span>
                      <span>
                        {item.paymentMode == 'cod' &&
                          <span className="pr-1">Cash</span>
                        }
                        {item.paymentMode == 'razorpay' &&
                          <span className="pr-1">Card</span>
                        }
                        {item.paymentMode != 'razorpay' && item.paymentMode != 'cod' &&
                          <span className="pr-1 payment-style">{item.paymentMode}</span>
                        }
                      </span>
                    </div>
                  </div>
                  <div className='row pt-1'>
                    <div className='col-5'>
                      {item.paymentMode != 'coupon' &&
                        <span>Payment Status</span>
                      }
                      {item.paymentMode == 'coupon' &&
                        <span>Discount Status</span>
                      }
                    </div>
                    <div className='col-7'>
                      : <span>{item.paymentStatus}
                        {item.paymentMode == 'razorpay' && (item.paymentStatus == 'Pending' || item.paymentStatus == 'Initiated') &&
                          <span className='pl-1'>
                            <Link className="btn btn-address font-14" onClick={initiatePayment} title="pay">Pay</Link>
                          </span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              {deliverySlot &&
                <div className='row pt-1'>
                  <div className='col-5'>
                    {order.orderTypeId === 1 &&
                      <span>Delivery</span>
                    }
                    {order.orderTypeId === 2 &&
                      <span>Pickup</span>
                    }
                  </div>
                  <div className='col-7'>: {general.getFormattedTime(deliverySlot.startTime)} - {general.getFormattedTime(deliverySlot.endTime)}</div>
                </div>
              }
              {order.orderTypeId === 1 &&
                <div className='row pt-1'>
                  <div className='col-5'>Delivery Address</div>
                  <div className='col-7'>: {address.fullAddress}</div>
                </div>
              }
              {order.orderTypeId === 2 &&
                <div className='row pt-1'>
                  <div className='col-5'>Pickup Address</div>
                  <div className='col-7'>: {order.stallAddress}</div>
                </div>
              }
            </div>

            <div>
              <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                <Table className="table-striped" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Items</TableCell>
                      <TableCell width={25}>Qty</TableCell>
                      <TableCell align="right" className='px-1'>Price</TableCell>
                      <TableCell width={100} align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lines.map((row: any) => (
                      <TableRow key={row.id}>
                        <TableCell className="table-font pr-1">
                          <span className='font-14'>{row.itemName}</span>
                          <div className='font-11 extras-txt'>
                            {row.orderLineModifiers && row.orderLineModifiers.map((custom, i) => (
                              <div>
                                <span>{custom.key}-</span>
                                {custom.displayModifiers && custom.displayModifiers.map((detail, idx) => (
                                  <span>{detail.description}
                                    {idx != (custom.displayModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                    {i != (row.orderLineModifiers.length - 1) &&
                                      <span>, </span>
                                    }
                                  </span>
                                ))}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className="table-font">
                          <span>{row.itemQuantity}</span>
                        </TableCell>
                        <TableCell align="right" className='px-1'>
                          <span className="table-font">{row.itemQuantity} * <FxCurrency value={row.price} />
                            {row.lineModifierAmount > 0 &&
                              <span>+ <FxCurrency value={row.lineModifierAmount} /></span>
                            }
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={row.lineTotalPrice} />
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TableCell align="right" colSpan={3} className='px-1'>
                        <span className="table-font">Order Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right" colSpan={3} className='px-1'>
                        <span className="table-font">Coupon Amount</span>
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.couponAmount} />
                        </span>
                      </TableCell>
                    </TableRow>
                    {
                      order.orderTypeId == 1 &&
                      <TableRow>
                        <TableCell align="right" colSpan={3} className='px-1'>
                          <span className="table-font">Delivery Charge</span>
                        </TableCell>
                        <TableCell align="right">
                          <span className="table-font">
                            <FxCurrency value={order.deliveryCharge} />
                          </span>
                        </TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell align="right" colSpan={3} className='px-1'>
                        {
                          order.orderTypeId == 1 &&
                          <span className="table-font">Total((Order Amount - Coupon Amount) + Delivery Charge)</span>
                        }
                        {
                          order.orderTypeId == 2 &&
                          <span className="table-font">Total(Order Amount - Coupon Amount)</span>
                        }
                      </TableCell>
                      <TableCell align="right">
                        <span className="table-font">
                          <FxCurrency value={order.orderTotal} />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>


          </div>
        </div>

      </div>
    </div>
  );
};

export default OrderSummary; 