import React, { useState } from 'react';
import { Box, Button, Dialog } from '@material-ui/core';
import './SignIn.scss';
import { FxInput, http, session, localstorage, formatService, AlertService } from '../fx-core';
import Link from '@material-ui/core/Link';
import { useDispatch } from 'react-redux';
import { Constants } from '../utils/Constants';
import { VerificationContainer } from '../VerificationContainer/VerificationContainer';
import { EmailVerification } from '../EmailVerification/EmailVerification';
import { MobileVerification } from '../MobileVerification/MobileVerification';
import { CommunityLink } from '../CommunityLink/CommunityLink';
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

const SignIn = (props: any) => {

  const clientId = Constants.GOOGLE_CLIENT_ID;
  const [valError, setValError] = useState(null);
  const [throwError, setThrowError] = useState(null);
  const dispatch = useDispatch();

  const cid = localstorage.getItem("communityId");
  const communityId = cid != null ? parseInt(cid) : 0;

  let defaultItem: any = {
    userName: '',
    password: '',
    OTP: '',
    id: 0,
    message: '',
    identity: '',
    communityId: communityId
  };
  const [item, setItem] = useState(defaultItem);
  const [loginType, setLoginType] = useState(null);
  const [error, setError] = useState(null);
  const [mobileVerifyOpen, setMobileVerifyOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [commuLinkOpen, setCommuLinkOpen] = useState(false);
  const [commuLinkObj, setCommuLinkObj] = useState({ message: "", link: "" });

  const handleInputChange = (item) => {
    handleItemChange({ [item.name]: item.value });
  }

  const handleItemChange = (data) => {
    setItem(prevState => {
      return { ...prevState, ...data }
    });
  };

  const handlePwdLogin = () => {
    setValError(null);
    setError(null);
    if (!item.identity || !item.password) {
      if (!item.identity) {
        setError("This field is required");
      }
      if (!item.password) {
        setValError("This field is required");
      }
      return;
    }
    let inputData = { UserName: item.identity, Password: item.password, CommunityId: item.communityId };
    let apiOptions = {
      url: 'security/authenticate/password',
      data: inputData
    };
    http.post(apiOptions).then(response => {
      signInCallBack(response);
    }).catch(error => {
      if (error && error.response) {
        setThrowError(error.response.data.message);
      }
    });
  }

  const signInCallBack = (response) => {
    let result = response.data;
    let authToken = result.authToken;
    if (authToken.errorCode == "COMMUNITY_NOT_MATCH" && authToken.communityMessage && authToken.communityLink) {
      let itemObj: any = { message: authToken.communityMessage, link: authToken.communityLink };
      setCommuLinkObj(itemObj);
      setCommuLinkOpen(true);
      return;
    }

    let userDisplayName = authToken.firstName;
    if (authToken.lastName) {
      userDisplayName = `${userDisplayName} ${authToken.lastName}`;
    }

    session.setItem('id_token', "token " + authToken.sessionId);
    localstorage.setItem('ltk', authToken.sessionId);
    localstorage.setItem('userId', authToken.userId);
    localstorage.setItem('roleCode', authToken.roleCode);
    localstorage.setItem('userDisplayName', userDisplayName);
    localstorage.setItem('username', authToken.username);
    localstorage.setItem('userRoles', JSON.stringify(result.roles));
    localstorage.setItem('email', authToken.email);

    if (authToken.roleCode == "SUPERADMIN") {
      dispatch({ type: "LOGIN_SUCCESS", payload: { isLogin: true } });
      props.handleSignInCallBack();
    }
    else {
      verification();
    }
  }

  const verification = () => {
    let apiOptions: any = {
      url: `user/Verification`,
      data: {}
    };
    http.post(apiOptions).then(res => {
      verificationCallback();
    }).catch(err => {
      if (err && err.correctiveActionCode) {
        if (err.correctiveActionCode == 'VERIFY_EMAIL') {
          openEmailVerification();
        }
        else if (err.correctiveActionCode == 'VERIFY_MOBILE') {
          openMobileVerification();
        }
        else if (err.correctiveActionCode == 'VERIFY_MOBILE,VERIFY_EMAIL') {
          openVerificationContainer();
        }
      }
    });
  }

  const verificationCallback = () => {
    dispatch({ type: "LOGIN_SUCCESS", payload: { isLogin: true } });
    props.handleSignInCallBack();
  }

  const openMobileVerification = () => {
    setMobileVerifyOpen(true);
  };

  const closeMobileVerification = () => {
    setMobileVerifyOpen(false);
  };

  const onSuccessMobileVerification = (res) => {
    closeMobileVerification();
    if (res == "success") {
      verificationCallback();
    }
  };

  const openEmailVerification = () => {
    setEmailVerifyOpen(true);
  };

  const closeEmailVerification = () => {
    setEmailVerifyOpen(false);
  };

  const onSuccessEmailVerification = (res) => {
    closeEmailVerification();
    if (res == "success") {
      verificationCallback();
    }
  };

  const openVerificationContainer = () => {
    setVerifyOpen(true);
  };

  const closeVerification = () => {
    setVerifyOpen(false);
  };

  const onSuccessVerification = (res) => {
    closeVerification();
    if (res == "success") {
      verificationCallback();
    }
  };

  const navToForgotPwd = () => {
    props.showForgotPwd();
  }

  const navToRegister = () => {
    props.navToRegister();
  }

  const navToBack = () => {
    props.backAction();
  }

  const onGoogleLoginSuccess = (googleResponse: any) => {
    const decoded: any = jwtDecode(googleResponse.credential);

    const email = decoded.email;
    const name = decoded.name;
    const userId = decoded.sub;
    let inputData = {
      email: email,
      communityId: item.communityId,
      displayName: name,
      userId: userId,
      externalProvider: "google",
      externalData: { email: email, userId: userId, displayName: name },
      currentDate: formatService.getCurrentDateForServer()
    }
    let apiOptions: any = {
      url: `security/registerconsumerforportal`,
      data: inputData
    };
    http.post(apiOptions).then(res => {
      signInCallBack(res);
    });
  }

  const onFailure = (response: any) => {
    console.log(response);
  }

  const getOtpForLogin = () => {
    setValError(null);
    setError(null);
    if (!item.identity) {
      setError("This field is required");
      return;
    }
    let inputData = {
      identity: item.identity
    }
    let apiOptions = {
      url: `users/getotp`,
      data: inputData
    };
    http.post(apiOptions).then(response => {
      getOtpCallBack(response.data);
    })
  }

  const getOtpCallBack = (res) => {
    let resultData = {
      id: res.userId,
      userName: res.userName
    }
    setItem(prevState => {
      return { ...prevState, ...resultData };
    });
    AlertService.showSuccessMsg(res.message);
    setLoginType("otp");
  }

  const navToPwdLogin = () => {
    setLoginType("pwd");
  }

  const navToOnload = () => {
    setLoginType(null);
  }

  const handleOtpLogin = () => {
    setValError(null);
    setError(null);
    if (!item.identity || !item.OTP || !item.id) {
      if (!item.identity) {
        setError("This field is required");
      }
      if (!item.OTP) {
        setValError("This field is required");
      }
      if (item.identity && item.OTP && !item.id) {
        AlertService.showErrorMsg("Please get otp and try to login");
      }
      return;
    }
    let apiOptions = {
      url: 'security/authenticate/otp',
      data: item
    };
    http.post(apiOptions).then(response => {
      signInCallBack(response);
    }).catch(error => {
      if (error && error.response) {
        setThrowError(error.response.data.message);
      }
    });
  }

  const closeCommuLink = () => {
    setCommuLinkOpen(false);
    setCommuLinkObj({ message: "", link: "" });
  }

  const body = (
    <div className="signIn-ui my-5">
      <div className="m-1">
        <FxInput name="identity" variant="outlined" label="User name / Email"
          value={item.identity || ''} onValueChange={handleInputChange} fullWidth />
        {error && !item.identity &&
          <div className="error"> User name / Email is required </div>}
      </div>

      {loginType == 'otp' &&
        <div className="m-1 pt-3">
          <FxInput name="OTP" variant="outlined" label="Code"
            type="password" value={item.OTP} onValueChange={handleInputChange} fullWidth />
          {valError && !item.OTP &&
            <div className="error"> Code is required </div>}
          {throwError &&
            <div className="error"> {throwError} </div>}
        </div>
      }

      {loginType == 'pwd' &&
        <div className="m-1 pt-3">
          <FxInput name="password" variant="outlined" label="Password"
            type="password" value={item.password}
            onValueChange={handleInputChange} fullWidth />
          {valError && !item.password &&
            <div className="error"> Password is required </div>}
          {throwError &&
            <div className="error"> {throwError} </div>}
        </div>
      }

      <div>
        <div className="text-center pt-2">
          {loginType == null &&
            <Button variant="contained" size="medium" color="primary" fullWidth onClick={getOtpForLogin}>
              Get Code
            </Button>
          }
          {loginType == 'pwd' &&
            <Button variant="contained" size="medium" color="primary" fullWidth onClick={handlePwdLogin}>
              Login
            </Button>
          }
          {loginType == 'otp' &&
            <Button variant="contained" size="medium" color="primary" fullWidth onClick={handleOtpLogin}>
              Login
            </Button>
          }
        </div>
        <Box sx={{ display: "flex", justifyContent: "center" }} className="text-center pt-4">
          <GoogleLogin
            onSuccess={(credentialResponse: any) => {
              onGoogleLoginSuccess(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            width="264px"
            size="medium"
          />
        </Box>
        {props.fromLanding &&
          <div>
            <div className="m-2 font-13 text-center pt-2">
              <Link href="#" onClick={navToRegister}><b>New User Sign up ?</b> </Link>
            </div>
          </div>
        }
        {(loginType == 'otp' || !loginType) &&
          <div className="m-2 mt-3 font-13 text-center">
            <Link href="#" onClick={navToPwdLogin}><b>Log in with Password</b> </Link>
          </div>
        }
        {loginType == 'pwd' &&
          <div className="m-2 mt-3 font-13 text-center">
            <Link href="#" onClick={navToOnload}><b>Log in with Code</b> </Link>
          </div>
        }
        {loginType == 'pwd' &&
          <div className="m-2 font-13 text-center pb-2 pt-2">
            <Link href="#" onClick={navToForgotPwd}><b>Forgot Password ?</b> </Link>
          </div>
        }
        {props.fromLanding &&
          <div>
            <div className="m-2 font-13 text-center pb-3">
              <Link onClick={navToBack} className="font-weight-bold pointer">Back</Link>
            </div>
          </div>
        }
      </div>
      <Dialog
        open={mobileVerifyOpen}
        onClose={closeMobileVerification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <MobileVerification handleVerification={onSuccessMobileVerification} closeDialog={closeMobileVerification} />
      </Dialog>
      <Dialog
        open={emailVerifyOpen}
        onClose={closeEmailVerification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <EmailVerification handleVerification={onSuccessEmailVerification} closeDialog={closeEmailVerification} />
      </Dialog>
      <Dialog
        open={verifyOpen}
        onClose={closeVerification}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <VerificationContainer handleVerification={onSuccessVerification} closeDialog={closeVerification} />
      </Dialog>
      <Dialog
        open={commuLinkOpen}
        onClose={closeCommuLink}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <CommunityLink message={commuLinkObj.message} link={commuLinkObj.link} closeDialog={closeCommuLink} />
      </Dialog>
    </div>
  );

  return (
    <div>
      {body}
    </div>
  );

}
export default SignIn;
