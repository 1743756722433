import React, { useState, useCallback, Fragment } from 'react';
import ProgressContext from './ProgressContext';
import Progress from './Progress';

export const ProgressProvider = ({ children, defaultOptions = {} }: any) => {
  const [open, setOpen] = useState(false);

  const progress = useCallback((options) => {
    return setOpen(options.open);
  }, []);

  return (
    <Fragment>
      <ProgressContext.Provider value={progress}>
        {children}
      </ProgressContext.Provider>
      {open && <Progress/> }
    </Fragment>
  );
};