import React from 'react';
import './DeliveryCharge.scss';
import { FxCurrency } from './../../fx-core';

export const DeliveryCharge = (props) => {

  return (
    <div>
      <div className="bold-text font-12 pl-2">
        <div className='font-11 pt-2'>{props.doorDeliveryMsg}</div>
        {/* {props.minimumOrderAmount > 0 &&
          <div className="row pl-3">
            <span>Minimun Order Amount:</span>
            <span className='pl-3'><FxCurrency value={props.minimumOrderAmount} /></span>
          </div>
        }
        {
          props.deliveryCharge > 0 &&
          <div>
            <div className="row pl-3">
              <span>Delivery Charge:</span>
              <span className='pl-3'><FxCurrency value={props.deliveryCharge} /></span>
            </div>
            <div className='pl-3 font-11 pt-2'>{props.deliveryChargeMsg}</div>
          </div>
        }
        {
          props.deliveryCharge == 0 &&
          <div>
            <div className="row pl-3">
              <span>Delivery Charge:</span>
              <span className='pl-3'>Free delivery</span>
            </div>
            <div className='font-11 pt-2'>{props.doorDeliveryMsg}</div>
          </div>
        } */}
      </div>
    </div>
  );
};